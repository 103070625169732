import React from 'react'

const General = () => {
  if (localStorage.getItem('IdRango') != 1 || localStorage.getItem('jwtToken')==null) {
  return (<div> NOT FOUND</div>);
}
  return (
    <div className="h-screen w-full overflow-hidden relative">
      <h1>VISTA ADMIN</h1>
    </div>
  );
};

export default General;