import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from '@nextui-org/react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ correo: username, password }),
      });

      if (response.ok) {
        const result = await response.json();

        // Almacenar el token y los datos de usuario/empresa en localStorage
        localStorage.setItem('jwtToken', result.token);
        localStorage.setItem('NombreEmpresa', result.NombreEmpresa);
        localStorage.setItem('NombreUsuario', result.NombreUsuario);
        localStorage.setItem('IdRango', result.IdRango);

        // Redireccionar a la ruta '/scanner'
        if(result.IdRango===3){
          navigate('/escaner-qr');
        }
        if(result.IdRango===2){
          navigate('/empresa');
        }
        if(result.IdRango===1){
          navigate('/vista-general');
        }
      } else {
        const result = await response.json();
        setError(result.bad ? 'Credenciales inválidas' : 'Error en el servidor');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setError('Error al conectar con el servidor');
    }
  };

  return (
    <div className='p-4'>
      <div className='grid grid-rows-1 grid-cols-1 mb-6'>
        <a className='mr-auto' href='/'>{"<"}</a>
      </div>
      <div className='p-[10px] sm:max-w-[400px] m-auto md:bg-slate-100 md:rounded-xl md:p-[40px]'>
        <h1 className='text-xl font-semibold mb-6 text-left pl-[10px]'>INICIAR SESION</h1>
        <form onSubmit={handleLogin} className='flex flex-col items-center'>
          <Input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="CORREO"
            required
            fullWidth
            clearable
            bordered
            className="mb-4 bg-transparent md:border-2 md:rounded-xl"
          />
          <Input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="CONTRASEÑA"
            required
            clearable
            className="mb-4 bg-transparent md:border-2 md:rounded-xl"
          />
          <Button type="submit" className='p-2 bg-blue-500 text-white rounded-md mt-4'>
            Login
          </Button>
          {error && <p className='mt-2 text-red-500'>{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;