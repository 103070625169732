import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';
import Eliminar from './eliminar';

const ModalEliminar = ({ isOpen, onClose, item }) => {
  return (
    <Modal isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent>
        <ModalHeader>Eliminar Producto</ModalHeader>
        <ModalBody>
          <Eliminar item={item} /> {/* Pasar el item al componente Eliminar */}
        </ModalBody>
        <ModalFooter>
          <Button onPress={onClose}>Cancelar</Button>
          <Button color="danger">Eliminar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEliminar;
