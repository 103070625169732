import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import { Card, CardHeader, CardBody, Divider, Avatar, Table, TableHeader, TableColumn, TableCell, TableBody, TableRow, Button } from '@nextui-org/react';

const HomePage = () => {
  const [empresas, setEmpresas] = useState([]);
  const navigate = useNavigate(); // Obtener la función navigate

  // Fetch de los datos de las empresas y sus descuentos desde la API
  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/descuento');
        const data = await response.json();
        setEmpresas(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEmpresas();
  }, []);

  // Función para manejar la navegación
  const handleCardClick = (nombre) => {
    navigate(`/${nombre}`);
  };

  return (
    <div>
      <header className='bg-slate-400 w-[100%] h-[60px] rounded-bl-md rounded-br-md grid grid-cols-2 grid-rows-1 p-[10px]'>
        <div>
          <h1 className='text-left text-xl font-sans font-bold'>QRDescto</h1>
        </div>
        <div className='ml-auto'>
          <Button color='primary' onClick={() => navigate("/login")}>
            Login
          </Button>
        </div>
      </header>

      <div className='max-w-[90%] ml-auto mr-auto mt-[30px]'>
        {empresas.map((empresa) => (
          <Card key={empresa.IdEmpresa} className='mb-[20px]'>
            <CardHeader onClick={() => handleCardClick(empresa.Nombre)}>
              <Avatar
                src={`data:image/jpeg;base64,${empresa.FotoPerfil}`}
                className="w-[50px] h-[50px]"
              />
              <p className='text-[25px] ml-[15px]'>{empresa.Nombre}</p>
            </CardHeader>
            <Divider />
            <CardBody>
              <h2 className='mb-[10px]'>Descuentos disponibles: {empresa.DescuentosDisponibles}</h2>
              <h3 className=' text-[0.8rem] text-center'>Descuento mas grande</h3>
              <Table aria-label="Descuento mas grande">
                <TableHeader>
                  <TableColumn>Descripción</TableColumn>
                  <TableColumn>% Descuento</TableColumn>
                  <TableColumn>Días restantes</TableColumn>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>{empresa.DescripcionDescuentoMaximo}</TableCell>
                    <TableCell>{empresa.DescuentoMaximo}%</TableCell>
                    <TableCell>{empresa.DiasHastaCaducidad} días</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HomePage;