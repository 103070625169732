import React, { useState } from 'react';
import { 
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Button, 
  Input, 
  Textarea,
  DatePicker 
} from "@nextui-org/react";

const ModalAgregar = ({ isOpen, closeModal }) => {
  const [nombreProducto, setNombreProducto] = useState('');
  const [descripcionProducto, setDescripcionProducto] = useState('');
  const [fechaFin, setFechaFin] = useState(null);
  const [banner, setBanner] = useState(null);
  const [politicas, setPoliticas] = useState('');
  const [descuento, setDescuento] = useState('');

  const handleAgregar = () => {
    // Lógica para agregar el producto
    console.log("Producto agregado:", {
      nombre: nombreProducto,
      descripcion: descripcionProducto,
      fechaFin,
      banner,
      politicas,
      descuento
    });
    closeModal(); // Cerrar modal después de agregar el producto
  };

  const handleFileChange = (e) => {
    setBanner(e.target.files[0]); // Guardar el archivo seleccionado
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={closeModal}>
      <ModalContent>
        <ModalHeader>Agregar Producto</ModalHeader>
        <ModalBody>
          <form>
            <Input 
              label="Nombre del Producto:" 
              value={nombreProducto}
              onChange={(e) => setNombreProducto(e.target.value)} 
              className="mb-4" // Agrega margen inferior
            />
            <Input 
              label="Descripción:" 
              value={descripcionProducto}
              onChange={(e) => setDescripcionProducto(e.target.value)} 
              className="mb-4" // Agrega margen inferior
            />
            <DatePicker 
              label="Fecha de Finalización"
              variant="bordered"
              onChange={setFechaFin} // Asigna la fecha seleccionada
              className="mb-4" // Agrega margen inferior
            />
            <Input 
              type="file" 
              placeholder='Banner'
              onChange={handleFileChange}
              className="mb-4" // Agrega margen inferior
            />
            <Textarea 
              label="Políticas" 
              value={politicas}
              onChange={(e) => setPoliticas(e.target.value)} 
              maxLength={250} 
              placeholder="Ingrese las políticas del producto aquí..."
              className="mb-4" // Agrega margen inferior
            />
            <Input 
              label="% de Descuento" 
              type="number" 
              value={descuento}
              onChange={(e) => setDescuento(e.target.value)} 
              className="mb-4" // Agrega margen inferior
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onPress={handleAgregar} color='success'>Agregar</Button>
          <Button onPress={closeModal}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAgregar;
