import React, { useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, Select, SelectItem } from "@nextui-org/react";
import { Accordion, AccordionItem } from "@nextui-org/react";

const ModalPerfil = ({ isOpen, closeModal }) => {
  // Estados para almacenar los valores de los inputs
  const [perfil, setPerfil] = useState({
    fotoPerfil: null,
    banner: null,
    carta: ''
  });

  const [redesSociales, setRedesSociales] = useState({
    whatsapp: '',
    instagram: '',
    facebook: '',
    web: '',
    direccion: '',
    telefonoFijo: '',
    email: ''
  });

  const [datosBancarios, setDatosBancarios] = useState({
    nombre: '',
    rut: '',
    tipoCuenta: '',
    numeroCuenta: '',
    nombreBanco: '',
    emailBanco: ''
  });

  // Funciones para manejar los cambios en los inputs
  const handlePerfilChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      // Si el input es de tipo archivo, usamos files[0]
      setPerfil({ ...perfil, [name]: files[0] });
    } else {
      setPerfil({ ...perfil, [name]: value });
    }
  };

  const handleRedesSocialesChange = (e) => {
    const { name, value } = e.target;
    setRedesSociales({ ...redesSociales, [name]: value });
  };

  const handleDatosBancariosChange = (e) => {
    const { name, value } = e.target;
    setDatosBancarios({ ...datosBancarios, [name]: value });
  };

  const handleTipoCuentaChange = (value) => {
    setDatosBancarios({ ...datosBancarios, tipoCuenta: value });
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={closeModal} scrollBehavior='inside'>
      <ModalContent>
        <ModalHeader>Perfil de la Empresa</ModalHeader>
        <ModalBody>
          <div className='p-[10px]'>
            {/* Acordeón de tres secciones */}
            <Accordion>
              {/* Primer acordeón: Foto de perfil, Banner y Carta */}
              <AccordionItem key="1" aria-label="Información General" title="Información General">
                <div className="flex flex-col gap-4">
                  <Input 
                    type="file" 
                    label="Foto de Perfil" 
                    name="fotoPerfil" 
                    onChange={handlePerfilChange} 
                    accept="image/*"
                  />
                  <Input 
                    type="file" 
                    label="Banner" 
                    name="banner" 
                    onChange={handlePerfilChange} 
                    accept="image/*"
                  />
                  <Input 
                    type="text" 
                    label="Carta" 
                    placeholder="Ingrese la carta de menú" 
                    name="carta" 
                    value={perfil.carta} 
                    onChange={handlePerfilChange} 
                  />
                </div>
              </AccordionItem>

              {/* Segundo acordeón: Redes Sociales */}
              <AccordionItem key="2" aria-label="Redes Sociales" title="Redes Sociales">
                <div className="flex flex-col gap-4">
                  <Input 
                    type="text" 
                    label="WhatsApp" 
                    placeholder="Ingrese su número de WhatsApp" 
                    name="whatsapp" 
                    value={redesSociales.whatsapp} 
                    onChange={handleRedesSocialesChange} 
                  />
                  <Input 
                    type="text" 
                    label="Instagram" 
                    placeholder="Ingrese su cuenta de Instagram" 
                    name="instagram" 
                    value={redesSociales.instagram} 
                    onChange={handleRedesSocialesChange} 
                  />
                  <Input 
                    type="text" 
                    label="Facebook" 
                    placeholder="Ingrese su cuenta de Facebook" 
                    name="facebook" 
                    value={redesSociales.facebook} 
                    onChange={handleRedesSocialesChange} 
                  />
                  <Input 
                    type="text" 
                    label="Web" 
                    placeholder="Ingrese su sitio web" 
                    name="web" 
                    value={redesSociales.web} 
                    onChange={handleRedesSocialesChange} 
                  />
                  <Input 
                    type="text" 
                    label="Dirección" 
                    placeholder="Ingrese su dirección" 
                    name="direccion" 
                    value={redesSociales.direccion} 
                    onChange={handleRedesSocialesChange} 
                  />
                  <Input 
                    type="text" 
                    label="Teléfono Fijo" 
                    placeholder="Ingrese su teléfono fijo" 
                    name="telefonoFijo" 
                    value={redesSociales.telefonoFijo} 
                    onChange={handleRedesSocialesChange} 
                  />
                  <Input 
                    type="email" 
                    label="Email" 
                    placeholder="Ingrese su email" 
                    name="email" 
                    value={redesSociales.email} 
                    onChange={handleRedesSocialesChange} 
                  />
                </div>
              </AccordionItem>

              {/* Tercer acordeón: Datos Bancarios */}
              <AccordionItem key="3" aria-label="Datos Bancarios" title="Datos Bancarios">
                <div className="flex flex-col gap-4">
                  <Input 
                    type="text" 
                    label="Nombre" 
                    placeholder="Ingrese el nombre del titular" 
                    name="nombre" 
                    value={datosBancarios.nombre} 
                    onChange={handleDatosBancariosChange} 
                  />
                  <Input 
                    type="text" 
                    label="RUT" 
                    placeholder="Ingrese el RUT" 
                    name="rut" 
                    value={datosBancarios.rut} 
                    onChange={handleDatosBancariosChange} 
                  />
                  
                  <Select 
                    label="Tipo de Cuenta" 
                    placeholder="Seleccione un tipo de cuenta"
                    name="tipoCuenta" 
                    selectedKey={datosBancarios.tipoCuenta}
                    onSelectionChange={handleTipoCuentaChange}
                  >
                    <SelectItem key="cuenta_corriente" value="Cuenta Corriente">
                      Cuenta Corriente
                    </SelectItem>
                    <SelectItem key="cuenta_ahorro" value="Cuenta de Ahorro">
                      Cuenta de Ahorro
                    </SelectItem>
                    <SelectItem key="cuenta_vista" value="Cuenta Vista">
                      Cuenta Vista
                    </SelectItem>
                  </Select>

                  <Input 
                    type="text" 
                    label="Número de Cuenta" 
                    placeholder="Ingrese el número de cuenta" 
                    name="numeroCuenta" 
                    value={datosBancarios.numeroCuenta} 
                    onChange={handleDatosBancariosChange} 
                  />
                  <Input 
                    type="text" 
                    label="Nombre del Banco" 
                    placeholder="Ingrese el nombre del banco" 
                    name="nombreBanco" 
                    value={datosBancarios.nombreBanco} 
                    onChange={handleDatosBancariosChange} 
                  />
                  <Input 
                    type="email" 
                    label="Email del Banco" 
                    placeholder="Ingrese el email registrado en el banco" 
                    name="emailBanco" 
                    value={datosBancarios.emailBanco} 
                    onChange={handleDatosBancariosChange} 
                  />
                </div>
              </AccordionItem>
            </Accordion>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='success'>Guardar</Button>
          <Button onPress={closeModal}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPerfil;
