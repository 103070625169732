import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';
import Modificar from './modificar';

const ModalModificar = ({ isOpen, onClose, item }) => {
  return (
    <Modal isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent>
        <ModalHeader>Modificar Producto</ModalHeader>
        <ModalBody>
          <Modificar item={item} /> {/* Pasar el item al componente Modificar */}
        </ModalBody>
        <ModalFooter>
          <Button onPress={onClose}>Cancelar</Button>
          <Button color="primary">Guardar Cambios</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalModificar;
