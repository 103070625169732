import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';

const ModalVer = ({ isOpen, onClose, item }) => {
  return (
    <Modal isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent>
        <ModalHeader>Detalles del Producto</ModalHeader>
        <ModalBody>
          {/* Aquí puedes mostrar los detalles del producto usando item */}
          <p><strong>Descripción:</strong> {item.Descripcion}</p>
          <p><strong>Descuento:</strong> {item.Descuento}</p>
          <p><strong>Días Restantes:</strong> {item.DiasRestantes}</p>
          <p><strong>Total Creados:</strong> {item.TotalCreados}</p>
          <p><strong>Total Canjeados:</strong> {item.TotalCanjeados}</p>
        </ModalBody>
        <ModalFooter>
          <Button onPress={onClose}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalVer;
