import React, { useState, useCallback } from 'react';
import { Table, TableHeader, TableColumn, TableRow, TableCell, TableBody } from '@nextui-org/react';
import { Tooltip } from '@nextui-org/react';
import { DeleteIcon } from './jsx/DeleteIcon';
import { EditIcon } from './jsx/EditIcon';
import { EyeIcon } from './jsx/EyeIcon';
import ModalVer from './ver';
import ModalModificar from './modificar';
import ModalEliminar from './eliminar';

const ProductTable = ({ data }) => {
  const [isViewOpen, setViewOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openViewModal = (item) => {
    setSelectedItem(item);
    setViewOpen(true);
  };

  const openEditModal = (item) => {
    setSelectedItem(item);
    setEditOpen(true);
  };

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setDeleteOpen(true);
  };

  const closeViewModal = () => setViewOpen(false);
  const closeEditModal = () => setEditOpen(false);
  const closeDeleteModal = () => setDeleteOpen(false);

  const columns = [
    { name: "Descripción", uid: "Descripcion" },
    { name: "% DESCUENTO", uid: "Descuento" },
    { name: "Días restantes", uid: "DiasRestantes" },
    { name: "Total creados", uid: "TotalCreados" },
    { name: "Total canjeados", uid: "TotalCanjeados" },
    { name: "Acciones", uid: "actions" },
  ];

  const renderCell = useCallback((item, columnKey) => {
    const cellValue = item[columnKey];
    switch (columnKey) {
      case "Descripcion":
        return <p className=' w-20'>{cellValue}</p>;
      case "Descuento":
        return <p className=' text-center'>{cellValue}</p>;
      case "DiasRestantes":
        return <p className=' text-center'>{cellValue}</p>;
      case "TotalCreados":
        return <p className=' text-center'>{cellValue}</p>;
      case "TotalCanjeados":
        return <p className=' text-center'>{cellValue}</p>;
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Ver">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => openViewModal(item)}>
                <EyeIcon />
              </span>
            </Tooltip>
            <Tooltip content="Modificar">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => openEditModal(item)}>
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip content="Eliminar">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => openDeleteModal(item)}>
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  return (
    <>
      <Table aria-label="Tabla de Productos" css={{ height: "auto", minWidth: "100%" }}>
        <TableHeader>
          {columns.map((column) => (
            <TableColumn key={column.uid}>{column.name}</TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item.Descripcion || index}>
              {columns.map((column) => (
                <TableCell key={column.uid}>{renderCell(item, column.uid)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal para Ver */}
      {selectedItem && (
        <ModalVer isOpen={isViewOpen} onClose={closeViewModal} item={selectedItem} />
      )}

      {/* Modal para Modificar */}
      {selectedItem && (
        <ModalModificar isOpen={isEditOpen} onClose={closeEditModal} item={selectedItem} />
      )}

      {/* Modal para Eliminar */}
      {selectedItem && (
        <ModalEliminar isOpen={isDeleteOpen} onClose={closeDeleteModal} item={selectedItem} />
      )}
    </>
  );
};

export default ProductTable;